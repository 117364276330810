/* Custom */

.highlight {
  margin-top: 1em;
  margin-bottom: 1em;
}

pre {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding: 1.25em 1.5em;
  margin-bottom: $code-block-margin-bottom;
  white-space: pre;
  word-wrap: normal;
  line-height: 1.2;

  > code {
    font-size: 0.8rem;
  }
}

.highlighter-rouge {
  font-size: 0.8rem;
  color: var(--scheme-main-ter);
  background-color: var(--scheme-invert-ter);
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  vertical-align: middle;
}

/* Copied from https://github.com/spsarolkar/rouge-theme-preview/tree/gh-pages */
.highlight pre {
  background-color: #333;
}
.highlight table td {
  padding: 5px;
}
.highlight table pre {
  margin: 0;
}
.highlight {
  color: #faf6e4;
  background-color: #122b3b;
}
.highlight .gl {
  color: #dee5e7;
  background-color: #4e5d62;
}
.highlight .gp {
  color: #a8e1fe;
  font-weight: bold;
}
.highlight .c,
.highlight .cd,
.highlight .cm,
.highlight .c1,
.highlight .cs {
  color: #6c8b9f;
  font-style: italic;
}
.highlight .cp {
  color: #b2fd6d;
  font-weight: bold;
  font-style: italic;
}
.highlight .err {
  color: #fefeec;
  background-color: #cc0000;
}
.highlight .gr {
  color: #cc0000;
  font-weight: bold;
  font-style: italic;
}
.highlight .k,
.highlight .kd,
.highlight .kv {
  color: #f6dd62;
  font-weight: bold;
}
.highlight .o,
.highlight .ow {
  color: #4df4ff;
  font-weight: bold;
}
.highlight .p,
.highlight .pi {
  color: #4df4ff;
}
.highlight .gd {
  color: #cc0000;
}
.highlight .gi {
  color: #b2fd6d;
}
.highlight .ge {
  font-style: italic;
}
.highlight .gs {
  font-weight: bold;
}
.highlight .gt {
  color: #dee5e7;
  background-color: #4e5d62;
}
.highlight .kc {
  color: #f696db;
  font-weight: bold;
}
.highlight .kn {
  color: #ffb000;
  font-weight: bold;
}
.highlight .kp {
  color: #ffb000;
  font-weight: bold;
}
.highlight .kr {
  color: #ffb000;
  font-weight: bold;
}
.highlight .gh {
  color: #ffb000;
  font-weight: bold;
}
.highlight .gu {
  color: #ffb000;
  font-weight: bold;
}
.highlight .kt {
  color: #b2fd6d;
  font-weight: bold;
}
.highlight .no {
  color: #b2fd6d;
  font-weight: bold;
}
.highlight .nc {
  color: #b2fd6d;
  font-weight: bold;
}
.highlight .nd {
  color: #b2fd6d;
  font-weight: bold;
}
.highlight .nn {
  color: #b2fd6d;
  font-weight: bold;
}
.highlight .bp {
  color: #b2fd6d;
  font-weight: bold;
}
.highlight .ne {
  color: #b2fd6d;
  font-weight: bold;
}
.highlight .nl {
  color: #ffb000;
  font-weight: bold;
}
.highlight .nt {
  color: #ffb000;
  font-weight: bold;
}
.highlight .m,
.highlight .mf,
.highlight .mh,
.highlight .mi,
.highlight .il,
.highlight .mo,
.highlight .mb,
.highlight .mx {
  color: #f696db;
  font-weight: bold;
}
.highlight .ld {
  color: #f696db;
  font-weight: bold;
}
.highlight .ss {
  color: #f696db;
  font-weight: bold;
}
.highlight .s,
.highlight .sb,
.highlight .sd,
.highlight .s2,
.highlight .sh,
.highlight .sx,
.highlight .sr,
.highlight .s1 {
  color: #fff0a6;
  font-weight: bold;
}
.highlight .se {
  color: #4df4ff;
  font-weight: bold;
}
.highlight .sc {
  color: #4df4ff;
  font-weight: bold;
}
.highlight .si {
  color: #4df4ff;
  font-weight: bold;
}
.highlight .nb {
  font-weight: bold;
}
.highlight .ni {
  color: #999999;
  font-weight: bold;
}
.highlight .w {
  color: #bbbbbb;
}
.highlight .go {
  color: #bbbbbb;
}
.highlight .nf {
  color: #a8e1fe;
}
.highlight .py {
  color: #a8e1fe;
}
.highlight .na {
  color: #a8e1fe;
}
.highlight .nv,
.highlight .vc,
.highlight .vg,
.highlight .vi {
  color: #a8e1fe;
  font-weight: bold;
}
