.theme-toggle-module--dark,
.theme-toggle-module--light {
  align-items: center;
  display: flex;
  justify-content: center;
}
.theme-toggle-module {
  position: fixed;
  border-radius: 9999px;
  cursor: pointer;
  display: block;
  outline: none;
  overflow: hidden;
  height: 12em;
  width: 12em;
  right: -6em;
  top: -6em;
  z-index: 10;
}
.theme-toggle-module--dark,
.theme-toggle-module--light,
.theme-toggle-module--wheel {
  transform-origin: center;
}
.theme-toggle-module--wheel {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 11em;
  justify-content: space-between;
  left: calc(50% - 2em);
  position: absolute;
  text-align: center;
  top: 0.5em;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
  transition-property: transform;
  width: 4em;
}
.theme-toggle-module--dark,
.theme-toggle-module--light {
  font-family: $emoji-font-family;
  font-size: 2rem;
  height: 2em;
  transform: rotate(-45deg);
}
.theme-toggle-module {
  background-color: rgba(255, 255, 0, 0.5);
}
.theme-toggle-module--wheel {
  transform: rotate(-135deg);
}

@mixin dark-theme-rules {
  .theme-toggle-module {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .theme-toggle-module--wheel {
    transform: rotate(45deg);
  }
}

@media (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    @include dark-theme-rules;
  }
}

:root[data-theme='dark'] {
  @include dark-theme-rules;
}

@include breakpoint(small only) {
  .theme-toggle-module {
    height: 8em;
    width: 8em;
    right: -4em;
    top: -4em;
    position: absolute;
  }
  .theme-toggle-module--wheel {
    scale: 0.6;
    left: calc(50% - 2.3em);
    top: -1.2em;
  }
}
