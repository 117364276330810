:root {
  @include dark-variables;
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     @include dark-variables;
//   }
// }

// @media (prefers-color-scheme: light) {
//   :root {
//     @include light-variables;
//   }
// }

:root[data-theme='dark'] {
  @include dark-variables;
}

:root[data-theme='light'] {
  @include light-variables;
}

body {
  transition-duration: 0.6s;
  transition-property: background-color;
  overflow-x: hidden;
  background: linear-gradient(rgb(13, 22, 33), rgb(13, 33, 49));
  min-height: 100vh;
}

header {
  line-height: 1.2;
  margin: 0 0 1rem 0;

  @include breakpoint(medium) {
    margin: 0 0 2rem 0;
  }

  text-align: center;
}

a {
  transition: color 0.2s ease, text-decoration 0.2s ease;
}

h3 a,
h4 a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.header-link {
  font-family: $header-font-family;
  font-weight: $header-font-weight;
  font-size: rem-calc(20);
  color: $grey-100;

  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 3px;
  transition: background-size 0.1s;
  padding-bottom: 6px;
  white-space: nowrap;

  &::after {
    border: 0;
  }

  &:hover {
    text-decoration: none;
    color: $grey-100;
    background-size: 100% 3px;
  }
}

.emoji-font {
  font-family: $emoji-font-family;
}

.grid-y > .auto {
  min-height: auto;
}

.article {
  h2,
  h3,
  h5 {
    margin-top: 2.5rem;
  }

  a {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: 4px;
      left: 0;
      width: 100%;
      transition: border-color 0.2s;
      border-bottom: 2px solid transparent;
    }

    &:hover {
      &::after {
        border-color: var(--link-hover);
      }
    }
  }
}

.post-preview {
  border: 1px solid transparent;
  border-radius: rem-calc(10);
  transition: border-color 0.2s;
  margin-bottom: 4rem;
  padding: 2rem 1rem;
  background-color: $galaxy-blue-900;

  color: inherit;

  &:hover {
    color: inherit;
    border-color: var(--link);
    box-shadow: $prototype-box-shadow;
  }
}

.text-primary {
  color: get-color(primary);
}

.min-height-100vh {
  min-height: 100vh;
}
